.Toaster__alert {
  background-color: #7a00ff;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  padding: 1rem;
  padding-right: 48px;
  box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px,
    rgba(52, 58, 64, 0.1) 0px 6px 12px 0px,
    rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
}

.Toaster__alert_text {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: rgb(33, 37, 41);
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0px;
}

.Toaster__alert_close {
  padding: 12px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  top: 7px;
  right: 4px;
  border-radius: 0.4rem;
  border: 0;
  -webkit-appearance: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.Toaster__alert_close:focus {
  box-shadow: rgba(52, 58, 64, 0.15) 0px 0px 0px 3px;
}

.Toaster__message-wrapper {
  padding: 8px;
}
